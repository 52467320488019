import { vfs, fonts, createPdf } from "pdfmake/build/pdfmake";
import { pdfMake } from "pdfmake/build/vfs_fonts";
import axios from "axios";
import { DateTime } from "luxon";

const compressPDF = async(filename, file) => {
    try {
        progressBarData(10, 0);
        //authenicate
        let response = await axios({
            method: "post",
            url: "https://api.ilovepdf.com/v1/auth",
            headers: {},
            data: { public_key: process.env.PUBLICKEY },
        });
        const { token } = response.data;

        progressBarData(10, 0);

        document.querySelector(".pdf-download-loading").classList.add("start");
        document.querySelector("body").classList.add("pdf-ganrate-start");
        document.querySelector(".floating").classList.add("pdf-start");
        document.querySelector(".pdf-dwonload-message").innerHTML =
            "Your PDF is being compressed and will be downloaded shortly.";

        //start compress
        response = await axios({
            method: "get",
            url: "https://api.ilovepdf.com/v1/start/compress",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        progressBarData(10, 0);
        const { server, task } = response.data;
        let data = new FormData();
        data.append("file", file, "report.pdf");
        data.append("task", task);
        // upload file
        response = await axios({
            method: "post",
            url: `https://${server}/v1/upload`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data,
        });

        progressBarData(25, 0);

        const { server_filename } = response.data;
        //process file
        response = await axios({
            method: "post",
            url: `https://${server}/v1/process`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                task,
                tool: "compress",
                files: [{
                    server_filename,
                    filename,
                }, ],
            },
        });
        progressBarData(5, 0);
        response = await axios({
            method: "get",
            url: `https://${server}/v1/download/${task}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        });

        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.pdf`);
        document.body.appendChild(link);

        window.open(url, "_blank");

        console.log(url, "url");
        console.log(link, "link");

        link.click();

        document.querySelector(".pdf-download-loading").classList.remove("start");
        document.querySelector("body").classList.remove("pdf-ganrate-start");
        document.querySelector(".floating").classList.remove("pdf-start");
        progressBarData(0, 1);

        return true;
    } catch (error) {
        throw Error(error);
    }
};

const generatePDF = async(id, printOnly) => {
    progressBarData(10, 0);
    return new Promise(async(resolve, reject) => {
        try {
            var url =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;

            var config = {
                method: "get",
                url: `${process.env.ZAPI}/${id}`,
                headers: {
                    Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
                },
            };

            // const endpoint = `${process.env.API}${id}`;
            // const { data } = await axios.get(endpoint);
            const { data } = await axios(config);
            const { data: limitations } = await axios("./public/limitations.txt");
            // const { data: limitations } = await axios("./limitations.txt");
            const { inspection, inspector, sections, conditions } = data;
            // const { inspection, inspector, layout } = data;
            const { sectionSummary, conditionsSummary } = data;
            const { client, address } = inspection;
            const { street, city, state, zipcode } = address;
            //date
            const startDateFormate = inspection.date.started;
            const endDateFormate = inspection.date.ended;
            const lastDotIndStartDate = startDateFormate.lastIndexOf(".");
            const lastDotIndEndDate = endDateFormate.lastIndexOf(".");
            const startdateformatted = startDateFormate.substring(
                0,
                lastDotIndStartDate
            );
            const enddateformatted = endDateFormate.substring(0, lastDotIndEndDate);

            const startDate = DateTime.fromJSDate(
                new Date(inspection.date.started)
            ).toLocaleString(DateTime.DATE_SHORT);
            const endDate = DateTime.fromJSDate(
                new Date(inspection.date.ended)
            ).toLocaleString(DateTime.DATE_SHORT);

            // const startTime = DateTime.fromJSDate(
            //   new Date(inspection.date.started)
            // ).toLocaleString(DateTime.TIME_SIMPLE);

            // const endTime = DateTime.fromJSDate(
            //   new Date(inspection.date.ended)
            // ).toLocaleString(DateTime.TIME_SIMPLE);
            // console.log(endTime);

            const startTime = DateTime.fromJSDate(
                new Date(startdateformatted)
            ).toLocaleString(DateTime.TIME_SIMPLE);

            const endTime = DateTime.fromJSDate(
                new Date(enddateformatted)
            ).toLocaleString(DateTime.TIME_SIMPLE);
            console.log(startTime);
            console.log(endTime);

            vfs = pdfMake.vfs;
            fonts = {
                Montserrat: {
                    bold: `${url}/public/fonts/Montserrat-SemiBold.ttf`,
                    normal: `${url}/public/fonts/Montserrat-Light.ttf`,
                },
            };
            // fonts = {
            //     Montserrat: {
            //         bold: `${url}/fonts/Montserrat-SemiBold.ttf`,
            //         normal: `${url}/fonts/Montserrat-Light.ttf`,
            //     },
            // };

            // if (typeof inspection.house.cover.downloadURL === "object") {
            //     var coverImage = inspection.house.cover.downloadURL.hd;
            // } else {
            //     var coverImage = inspection.house.cover.downloadURL;
            // }

            const dd = {
                pageSize: "LETTER",
                pageMargins: [40, 100, 40, 40],
                header: {
                    columns: [
                        { image: "logo", width: 150 },
                        {
                            text: `${inspector.fname} ${inspector.lname}\n${inspector.licence_number}\n${inspector.phone} \n${inspector.email}`,
                            alignment: "right",
                            fontSize: 12,
                            bold: true,
                        },
                    ],
                    margin: [40, 20],
                },
                footer: function(currentPage, pageCount) {
                    return {
                        text: `Page ${currentPage} of ${pageCount}`,
                        alignment: "center",
                        margin: [0, 0, 0, 50],
                    };
                },
                content: [
                    { image: "cover", width: 530, margin: [0, 20] },
                    {
                        columns: [{
                                text: `${client.name}\n${street}\n${city} ${state} ${zipcode}`,
                                bold: true,
                            },
                            // {
                            //     text: `\n\n${inspection.date.started}`,
                            //     alignment: "right",
                            //     bold: true,
                            // },
                            {
                                text: `\n\n${startDate}`,
                                alignment: "right",
                            },
                        ],
                    },
                    {
                        toc: {
                            title: {
                                text: "INSPECTION REPORT INDEX",
                                pageBreak: "before",
                                style: ["title"],
                            },
                        },
                    },
                ],
                styles: {
                    title: { fontSize: 36, bold: true, margin: [0, 5] },
                    header: { fontSize: 24, bold: true, margin: [0, 5] },
                    subHeader: { fontSize: 14, bold: true, margin: [0, 5] },
                    limitation: { fontSize: 7 },
                },
                defaultStyle: {
                    font: "Montserrat",
                },
                images: {
                    logo: `${url}/public/images/HDMK.png`,
                    // cover: coverImage || `${url}/public/images/cover.png`,
                    // logo: `${url}/images/HDMK.png`,
                    cover: inspection.cover,
                    // logo: `${url}/public/images/HDMK.png`,
                },
            };

            dd.content.push({
                text: `INSPECTION CONDITIONS`,
                pageBreak: "before",
                tocItem: true,
                style: ["title"],
            });
            dd.content.push({
                text: `CLIENT & SITE INFORMATION`,
                style: ["header"],
            });
            dd.content.push({
                layout: "noBorders",
                margin: [20, 0],
                table: {
                    widths: "*",
                    body: [
                        [
                            { text: "DATE OF INSPECTION:", style: ["subHeader"] },
                            { text: startDate, margin: [0, 3] },
                        ],
                        [
                            { text: "START TIME:", style: ["subHeader"] },
                            { text: startTime, margin: [0, 3] },
                        ],
                        [
                            { text: "END TIME:", style: ["subHeader"] },
                            { text: endTime, margin: [0, 3] },
                        ],
                        [
                            { text: "CLIENT NAME:", style: ["subHeader"] },
                            { text: client.name, margin: [0, 3] },
                        ],
                        [
                            { text: "CITY & STATE:", style: ["subHeader"] },
                            {
                                text: `${city}, ${state} ${zipcode}`,
                                margin: [0, 3],
                            },
                        ],
                    ],
                },
            });
            dd.content.push({ text: "PAYMENT INFORMATION:", style: ["header"] });
            dd.content.push({
                layout: "noBorders",
                table: {
                    widths: "*",
                    body: [
                        [
                            { text: "TOTAL FEE:", style: ["subHeader"] },
                            { text: `$${inspection.fee}`, margin: [0, 3] },
                        ],
                        // ["SIGNATURE:", { image: "signature", width: 100 }],
                    ],
                },
            });

            //conditions page
            const createConditionsPage = () => {
                //Render conditions
                conditions.forEach((condition) => {
                    dd.content.push({ text: condition.name, style: ["header"] });
                    condition.comments.forEach((comment) => {
                        dd.content.push({
                            layout: "noBorders",
                            table: {
                                widths: "*",
                                body: [
                                    [
                                        { text: comment.name, style: ["subHeader"] },
                                        {
                                            text: comment.text,
                                            margin: [0, 3],
                                        },
                                    ],
                                ],
                            },
                        });
                    });
                });
            };

            //limitation page
            const createLimitationsPage = () => {
                dd.content.push({
                    text: "REPORT LIMITATIONS:",
                    pageBreak: "before",
                    tocItem: true,
                    style: ["title"],
                });
                dd.content.push({ text: limitations, style: ["limitation"] });
            };

            //summary page
            const createSummaryPage = () => {
                //RENDER SUMMARY
                dd.content.push({
                    text: `REPORT SUMMARY`,
                    pageBreak: "before",
                    tocItem: true,
                    style: ["title"],
                });
                sectionSummary.forEach((section) => {
                    dd.content.push({ text: section.name, style: ["header"] });
                    section.items.forEach((item) => {
                        dd.content.push({
                            text: item.name,
                            style: ["subHeader"],
                            margin: [10, 0],
                        });
                        item.comments.forEach((comment) => {
                            let columns = [];
                            dd.content.push({
                                stack: [
                                    { text: comment.name, margin: [20, 5, 0, 5] },
                                    { text: comment.text, margin: [20, 5, 0, 5] },
                                ],
                            });
                            console.log(comment.media, "comment madia");
                            comment.media.forEach((media, index) => {
                                if (media) {
                                    if (media.type === "image/jpeg" && media.uploaded) {
                                        dd.images[media.name] = media.url;
                                        columns.push({
                                            image: media.name,
                                            width: 150,
                                            height: 150,
                                            margin: [10, 4],
                                        });
                                        if (!comment.media[index + 1]) {
                                            return dd.content.push({
                                                columns: columns,
                                                columnGap: 8,
                                            });
                                        }

                                        if (!(columns.length % 3)) {
                                            dd.content.push({
                                                columns: columns,
                                                columnGap: 8,
                                                margin: [0, 4],
                                            });
                                            columns = [];
                                        }
                                    }
                                }
                            });
                        });
                    });
                });
                conditionsSummary.forEach((condition) => {
                    dd.content.push({ text: condition.name, style: ["header"] });
                    condition.comments.forEach((comment) => {
                        dd.content.push({
                            stack: [
                                { text: comment.name, margin: [20, 5, 0, 5] },
                                { text: comment.text, margin: [20, 5, 0, 5] },
                            ],
                        });
                    });
                });
            };

            //sections page
            const createSectionsPage = () => {
                //RENDER SECTIONS
                sections.forEach((section) => {
                    dd.content.push({
                        text: section.name,
                        pageBreak: "before",
                        tocItem: true,
                        style: ["title"],
                    });
                    section.items.forEach((item) => {
                        dd.content.push({ text: item.name, style: ["header"] });
                        item.comments.forEach((comment) => {
                            let columns = [];
                            dd.content.push({
                                stack: [{
                                        text: comment.name,
                                        style: ["subHeader"],
                                        margin: [10, 5, 0, 5],
                                    },
                                    { text: comment.text, margin: [10, 5, 0, 5] },
                                ],
                            });
                            comment.media.forEach((media, index) => {
                                if (media) {
                                    if (media.type === "image/jpeg" && media.uploaded) {
                                        dd.images[media.name] = media.url;
                                        columns.push({
                                            image: media.name,
                                            width: 150,
                                            height: 150,
                                            margin: [10, 4],
                                        });
                                        if (!comment.media[index + 1]) {
                                            return dd.content.push({
                                                columns: columns,
                                                columnGap: 8,
                                            });
                                        }

                                        if (!(columns.length % 3)) {
                                            dd.content.push({
                                                columns: columns,
                                                columnGap: 8,
                                                margin: [0, 4],
                                            });
                                            columns = [];
                                        }
                                    }
                                }
                            });
                        });
                    });
                });
            };

            createConditionsPage();
            createLimitationsPage();
            // createSummaryPage();
            // createSectionsPage();
            if (printOnly === "summary") {
                createSummaryPage();
            }

            if (printOnly === "sections") {
                createSectionsPage();
            }

            if (printOnly === "full") {
                createSummaryPage();
                createSectionsPage();
            }

            progressBarData(10, 0);
            const doc = createPdf(dd);
            progressBarData(10, 0);

            doc.getBlob((blob) => {
                resolve(compressPDF(`${street} ${city} ${state} ${zipcode}`, blob));
            });

            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

const generateDownloadPDF = async(id, printOnly) => {
    try {
        var url =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;

        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        // const endpoint = `${process.env.API}${id}`;
        // const { data } = await axios.get(endpoint);
        const { data } = await axios(config);
        const { data: limitations } = await axios("./public/limitations.txt");
        // const { data: limitations } = await axios("./limitations.txt");
        const { inspection, inspector, sections, conditions } = data;
        // const { inspection, inspector, layout } = data;
        const { sectionSummary, conditionsSummary } = data;
        const { client, address } = inspection;
        const { street, city, state, zipcode } = address;

        return inspection.downloadURL;
    } catch (error) {
        reject(error);
    }
};

//full inspection report

const onCreateFullInspectionReport = async() => {
    const id = document.getElementById("full-report-pdf").getAttribute("data");
    const printOnly = "full";

    const downloadUrl = await generateDownloadPDF(id, printOnly);
    if (typeof downloadUrl !== "undefined" && downloadUrl !== null) {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${downloadUrl}.pdf`);
        document.body.appendChild(link);
        window.open(downloadUrl, "_blank");
    } else {
        progressBarData(0, 1);
        document.querySelector(".pdf-download-loading").classList.add("start");
        document.querySelector("body").classList.add("pdf-ganrate-start");
        document.querySelector(".floating").classList.add("pdf-start");
        document.querySelector(".pdf-dwonload-message").innerHTML =
            "Your PDF is being created. This process may take up to 2 minutes. Next we will compress your PDF. Please don’t click the button multiple times.";
        document.querySelector(".wrapper input").checked = false;
        await generatePDF(id, printOnly);
    }
};

// Gnerate summary report pdf
const generateSummaryInspectionReportPDF = async(id, printOnly) => {
    try {
        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        const { data } = await axios(config);

        return data.inspection.summaryURL;
    } catch (error) {
        console.error(error.message);
        reject(error);
    }
}

//summery report

const onCreateSummaryInspectionReport = async() => {
    progressBarData(0, 1);
    const id = document.getElementById("summary-report-pdf").getAttribute("data");
    const printOnly = "summary";

    const summaryURL = await generateSummaryInspectionReportPDF(id, printOnly)

    if (summaryURL) {
        window.open(summaryURL, "_blank");
    }

    // document.querySelector(".pdf-download-loading").classList.add("start");
    // document.querySelector("body").classList.add("pdf-ganrate-start");
    // document.querySelector(".floating").classList.add("pdf-start");
    // document.querySelector(".pdf-dwonload-message").innerHTML =
    //   "Your PDF is being created. This process may take up to 2 minutes. Next we will compress your PDF. Please don’t click the button multiple times.";
    // document.querySelector(".wrapper input").checked = false;
    // await generatePDF(id, printOnly);
};



// Gnerate WDIR report pdf
const generateWDIRInspectionReportPDF = async(id, printOnly) => {
    try {
        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        const { data } = await axios(config);

        return data.inspection.wdir;
    } catch (error) {
        console.error(error.message);
        reject(error);
    }
}

//WDIR
const onCreateWDIRReport = async() => {
    progressBarData(0, 1);
    const id = document.getElementById("wdir-report-pdf").getAttribute("data");
    const printOnly = "WDIR";

    const WDIRURL = await generateWDIRInspectionReportPDF(id, printOnly);

    if (WDIRURL) {
        window.open(WDIRURL, "_blank");
    }

};


// Gnerate Fout Point report pdf
const generateFourPointInspectionReportPDF = async(id, printOnly) => {
    try {
        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        const { data } = await axios(config);

        return data.inspection.fourPoint;
    } catch (error) {
        console.error(error.message);
        reject(error);
    }
}


// Four point
const onCreateFourPointReport = async() => {
    progressBarData(0, 1);
    const id = document.getElementById("four-point-report-pdf").getAttribute("data");
    const printOnly = "4-Point";

    const fourPointURL = await generateFourPointInspectionReportPDF(id, printOnly);

    if (fourPointURL) {
        window.open(fourPointURL, "_blank");
    }

};




// Gnerate Wind mit report pdf
const generateWindMitInspectionReportPDF = async(id, printOnly) => {
    try {
        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        const { data } = await axios(config);

        return data.inspection.windMit;
    } catch (error) {
        console.error(error.message);
        reject(error);
    }
}

// Wind mit
const onCreateWindMitReport = async() => {
    progressBarData(0, 1);
    const id = document.getElementById("wind-mit-report-pdf").getAttribute("data");
    const printOnly = "Wind Mit.";

    const windMitURL = await generateWindMitInspectionReportPDF(id, printOnly);

    if (windMitURL) {
        window.open(windMitURL, "_blank");
    }

};



// Gnerate Trec report pdf
const generateTrecInspectionReportPDF = async(id, printOnly) => {
    try {
        var config = {
            method: "get",
            url: `${process.env.ZAPI}/${id}`,
            headers: {
                Authorization: `Bearer ${process.env.ACCESSTOKEN}`,
            },
        };

        const { data } = await axios(config);

        return data.inspection.trec;
    } catch (error) {
        console.error(error.message);
        reject(error);
    }
}

//TREC

const onCreateTrecReport = async() => {
    progressBarData(0, 1);
    const id = document.getElementById("trec-report-pdf").getAttribute("data");
    const printOnly = "TREC REI 7-6";

    const TRECURL = await generateTrecInspectionReportPDF(id, printOnly);

    if (TRECURL) {
        window.open(WDIRTRECURLURL, "_blank");
    }

};

var fullReport = document.querySelector("#full-report-pdf");
if (fullReport) {
    fullReport.addEventListener("click", onCreateFullInspectionReport);
}

var summaryReport = document.querySelector("#summary-report-pdf");
if (summaryReport) {
    summaryReport.addEventListener("click", onCreateSummaryInspectionReport);
}

var wdirReport = document.querySelector("#wdir-report-pdf");
if (wdirReport) {
    wdirReport.addEventListener("click", onCreateWDIRReport);
}

var fourPointReport = document.querySelector("#four-point-report-pdf");
if (fourPointReport) {
    fourPointReport.addEventListener("click", onCreateFourPointReport);
}

var trecReport = document.querySelector("#trec-report-pdf");
if (trecReport) {
    trecReport.addEventListener("click", onCreateTrecReport);
}

var windMit = document.querySelector("#wind-mit-report-pdf");
if (windMit) {
    windMit.addEventListener("click", onCreateWindMitReport);
}


function progressBarData(value, clear = 0) {
    setTimeout(function() {
        if (clear) {
            $("#report-progress-bar").val(value);
        } else {
            let oldValue = $("#report-progress-bar").val();
            let newValue = oldValue + value;
            console.log(newValue);
            $("#report-progress-bar").val(newValue);
        }
    }, 200);
}